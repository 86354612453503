
import React from "react"
import {maskCep, maskDate, maskPhone} from "../components/mask-str";
import * as CepService from "../services/cep.service";
import {isEmail, validarCNPJ, validarCPF} from "../services/utils.service";
import * as dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import {register} from "../services/auth";
import {navigate} from "gatsby";

dayjs.extend(customParseFormat);

class RegistrarComponent extends React.Component {

    state={
        loading:false,
        fieldsWithError:{},
        formisValid:false,
        "user":{
            "cpfCnpj": null,
            "email":null,
            "password":null
        },
        "cliente":{
            "cpfCnpj": null,
            "nomeCompleto": null,
            "email":null,
            "genero":null,
            "telefoneCelular":null,
            "isWhatsapp":false,
            "dataNascimento":null,
            "assinaNewsletter":false
        },
        "endereco":{
            "apelido":null,
            "cep":null,
            "logradouro":null,
            "complemento":null,
            "bairro":null,
            "localidade":null,
            "uf":null,
            "numero":null
        }
    }

    constructor(props) {
        super(props);
        this.handleCliente = this.handleCliente.bind(this)
        this.handleUser = this.handleUser.bind(this)
        this.handleEndereco = this.handleEndereco.bind(this)
        this.searchCepEndereco = this.searchCepEndereco.bind(this)
        this.cadastrarCliente = this.cadastrarCliente.bind(this)
    }

    handleUser(event) {
        let target = event.target;
        let userChanges = {
            [target.name]: target.value,
        };
        userChanges = Object.assign(this.state.user,userChanges);
        if(this.state.fieldsWithError[target.name]){
            if(this.isFieldValid(target)){
                delete this.state.fieldsWithError[target.name]
            }
        }
        this.setState({user:userChanges})

    }

    handleEndereco(event,formatter) {
        let target = event.target;
        if(!target.value){
            return;
        }

        let userChanges = null;

        if(target.type==='checkbox'){
            userChanges = {
                [target.name]: target.checked,
            };
        }else{
            let value = target.value;
            if(value && formatter){
                value = formatter?formatter(target.value.trim()):target.value.trim();
            }
            userChanges = {
                [target.name]: value,
            };
        }

        userChanges = Object.assign(this.state.endereco,userChanges);
        if(this.state.fieldsWithError[target.name]){
            if(this.isFieldValid(target)){
                delete this.state.fieldsWithError[target.name]
            }
        }
        this.setState({endereco:userChanges})

    }

    searchCepEndereco(event){
        this.setState({
            loading: true
        });

        CepService.loadCep(this.state.endereco.cep).then(enderecoViaCep=>{

            /*
            "cep": "66813-750",
              "logradouro": "Travessa N-5",
              "complemento": "(Cj COHAB)",
              "bairro": "Campina de Icoaraci (Icoaraci)",
              "localidade": "Belém",
              "uf": "PA",
              "ibge": "1501402",
              "gia": "",
              "ddd": "91",
              "siafi": "0427"
             */
            this.state.endereco.logradouro = enderecoViaCep.logradouro;
            this.state.endereco.complemento = enderecoViaCep.complemento;
            this.state.endereco.bairro = enderecoViaCep.bairro;
            this.state.endereco.localidade = enderecoViaCep.localidade;
            this.state.endereco.uf = enderecoViaCep.uf;

            delete this.state.fieldsWithError['logradouro'];
            delete this.state.fieldsWithError['complemento'];
            delete this.state.fieldsWithError['bairro'];
            delete this.state.fieldsWithError['localidade'];
            delete this.state.fieldsWithError['uf'];

            // console.log(enderecoViaCep)

        }).catch(err=>{
        }).finally(()=>{
            this.setState({
                loading: false
            });
        })


    }

    handleCliente(event,formmatter) {
        let target = event.target;

        let userChanges = null;

        if(target.type==='checkbox'){
            userChanges = {
                [target.name]: target.checked,
            };
        }else{
            let value = target.value;
            if(value && formmatter){
                value = formmatter?formmatter(target.value.trim()):target.value.trim();
            }
            userChanges = {
                [target.name]: value,
            };
        }

        userChanges = Object.assign(this.state.cliente,userChanges);
        if(this.state.fieldsWithError[target.name]){
            let fieldValid = this.isFieldValid(target);
            if(fieldValid){
                this.state.fieldsWithError[target.name] = false;
                delete this.state.fieldsWithError[target.name]
            }
        }
        this.setState({cliente:userChanges})

    }

    validateForm(){
        this.setState({
            loading: true
        });
        this.state.fieldsWithError={}
        var fieldsToValidateList = Array.from(document.getElementsByClassName('form-field'))
        fieldsToValidateList.forEach(field=>{
            if(this.isFieldValid(field)===false){
                this.state.fieldsWithError[field.name] = true;
            }
        })

        this.state.formisValid = Object.keys(this.state.fieldsWithError).length===0;
        this.setState({
            loading: false
        });
    }

    isFieldValid(target){

        let result = true;
        let validationMap ={
            cpfCnpj:()=>{
                if(target.value.length===11){
                    result = validarCPF(target.value);
                }else if(target.value.length===14){
                    result = validarCNPJ(target.value);
                }else{
                    result = false;
                }
            },
            cep:()=>{
                result = target.value.replace(/\D/g,'').length===8  ;
            },
            dataNascimento:()=>{
                let dataNascimento = dayjs(target.value,'DD/MM/YYYY');
                result = dataNascimento.isValid()

            },
            password:()=>{
                result = target.value.length>=4  ;
            },
        }

        if(target.required){

            if((target.value==='' || !!target.value===false )){
                result = false;
            }else{
                validationMap[target.name] && validationMap[target.name]();
            }

        }

        return result

    }

    cadastrarCliente(){


        this.validateForm()
        if(this.state.formisValid){
            this.setState({
                loading: true
            });
            let formData = {
                user:Object.assign({},this.state.user),
                cliente:Object.assign({},this.state.cliente),
                endereco:Object.assign({},this.state.endereco),
            }

            formData.cliente.cpfCnpj = formData.cliente.cpfCnpj.trim().replace(/[^\d]+/g, '');
            formData.cliente.telefoneCelular = formData.cliente.telefoneCelular.trim().replace(/[^\d]+/g, '');

            let dayjs1 = dayjs(formData.cliente.dataNascimento,'DD/MM/YYYY');
            let dataNascimento = dayjs1.format('YYYY-MM-DD');

            formData.user.cpfCnpj = formData.cliente.cpfCnpj;
            formData.user.email = formData.cliente.email;

            formData.cliente.dataNascimento = dataNascimento;

            register(formData).then(()=>{
                navigate(`/`)
            }).catch(err=>{
                if(err.status && err.json){
                    err.json().then(body=>{

                        if(err.status===400 && body.message){
                            alert(body.message)
                        }
                    })
                }else{
                    alert('Erro ao acessar serviço. Tente mais tarde')

                }
            }).finally(()=>{
                this.setState({
                    loading: false
                });
            })
        }

    }


    render() {
        return (
            <div className="product-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                            <div className="account-form">
                                <h4 className="text-center mb-30">Crie sua conta</h4>
                                <form method="post">
                                    <fieldset disabled={this.state.loading}>
                                        <div className="row">
                                            <div className={`col-md-6 form-group ${this.state.fieldsWithError['nomeCompleto']?'has-error':''}`}>
                                                <label className="">Nome completo <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" name="nomeCompleto" id="nomeCompleto" required
                                                       value={this.state.cliente.nomeCompleto}
                                                       onChange={this.handleCliente}
                                                       placeholder="Nome completo" />
                                            </div>

                                            <div className={`col-md-6 form-group ${this.state.fieldsWithError['cpfCnpj']?'has-error':''}`}>
                                                <label className="">CPF / CNPJ <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.cliente.cpfCnpj}
                                                       name="cpfCnpj" onChange={this.handleCliente}
                                                       placeholder="" />
                                            </div>

                                            <div className={`col-md-12 form-group ${this.state.fieldsWithError['email']?'has-error':''}`}>
                                                <label className="">E-mail <span className="text-danger">*</span> <small>Necessário para recuperação de senha</small> </label>
                                                <input type="email" className="form-control input-lg form-field" required
                                                       name="email" onChange={this.handleCliente}
                                                       value={this.state.cliente.email}
                                                       placeholder="" />
                                                <label className="mt15px">
                                                     <span className="mr15px">Assinar newsletter</span>

                                                    <input type="checkbox" checked={this.state.cliente.assinaNewsletter} name="assinaNewsletter" onClick={this.handleCliente} />
                                                </label>

                                            </div>

                                            <div className={`col-md-12 form-group ${this.state.fieldsWithError['telefoneCelular']?'has-error':''}`}>
                                                <label className="">Telefone celular <span className="text-danger">*</span> <small>Necessário para atualização de pedidos</small> </label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       name="telefoneCelular"
                                                       value={this.state.cliente.telefoneCelular?maskPhone(this.state.cliente.telefoneCelular):null}
                                                       onChange={(event)=> this.handleCliente(event,maskPhone)}
                                                       placeholder="" />
                                                <label className="mt15px">
                                                    <span className="mr15px">whatsapp</span>
                                                    <input type="checkbox" checked={this.state.cliente.isWhatsapp} name="isWhatsapp" onClick={this.handleCliente} />
                                                </label>
                                            </div>

                                            <div className={`col-md-12 form-group ${this.state.fieldsWithError['dataNascimento']?'has-error':''}`}>
                                                <label className="">Data nascimento</label>
                                                <input type="text" className="form-control input-lg form-field" maxLength={10}
                                                       value={this.state.cliente.dataNascimento}
                                                       name="dataNascimento"
                                                       onChange={(event)=> this.handleCliente(event,maskDate)}
                                                       placeholder="" />
                                            </div>

                                            <div className={`col-md-12 form-group ${this.state.fieldsWithError['password']?'has-error':''}`}>
                                                <label className="">Senha <span className="text-danger">*</span> <small>Mínimo de 3 caracteres</small></label>
                                                <input type="password" className="form-control input-lg form-field" required name="password"
                                                       value={this.state.user.password}
                                                       onChange={(event)=> this.handleUser(event)}
                                                       placeholder="" />
                                            </div>

                                            <hr/>
                                            <h4 className="text-center mb-30">Endereço</h4>

                                                <div className={`col-md-6 form-group ${this.state.fieldsWithError['apelido']?'has-error':''}`}>
                                                    <label className="">Identificação  <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control input-lg form-field" name="apelido"
                                                           onChange={this.handleEndereco} required
                                                           placeholder="Ex: casa, trabalho..." value={this.state.endereco.apelido} />
                                                </div>

                                                <div className={`col-md-6 form-group ${this.state.fieldsWithError['cep']?'has-error':''}`}>
                                                    <label className="">CEP <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control input-lg form-field"
                                                           name="cep" required
                                                           value={this.state.endereco.cep?maskCep(this.state.endereco.cep):null}
                                                           onBlur={this.searchCepEndereco}
                                                           onChange={(event)=> this.handleEndereco(event,maskCep)}
                                                           placeholder="" />
                                                </div>


                                            <div className={`col-md-8 form-group ${this.state.fieldsWithError['logradouro']?'has-error':''}`}>
                                                <label className="">Endereco <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field"
                                                       name="logradouro" onChange={this.handleEndereco} required
                                                       value={this.state.endereco.logradouro}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-4 form-group ${this.state.fieldsWithError['numero']?'has-error':''}`}>
                                                <label className="">Número <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       name="numero" onChange={this.handleEndereco}
                                                       value={this.state.endereco.numero}
                                                       placeholder="" />
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <label className="">Complemento</label>
                                                <input type="text" className="form-control input-lg form-field"
                                                       value={this.state.endereco.complemento}
                                                       name="complemento" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>

                                            <div className={`col-md-5 form-group ${this.state.fieldsWithError['bairro']?'has-error':''}`}>
                                                <label className="">Bairro <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.bairro}
                                                       name="bairro" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-5 form-group ${this.state.fieldsWithError['localidade']?'has-error':''}`}>
                                                <label className="">Localidade <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.localidade}
                                                       name="localidade" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>
                                            <div className={`col-md-2 form-group ${this.state.fieldsWithError['uf']?'has-error':''}`}>
                                                <label className="">UF <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control input-lg form-field" required
                                                       value={this.state.endereco.uf}
                                                       name="uf" onChange={this.handleEndereco}
                                                       placeholder="" />
                                            </div>

                                            {this.state.loading?(
                                                    <div className="mt-30 text-center">
                                                        <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                    </div>
                                                ):null}

                                            <div className="col-md-12 form-group">
                                                <button type='button' className="btn btn-block btn-round btn-base" onClick={this.cadastrarCliente}>Cadastrar</button>
                                            </div>

                                            {/*<div className="col-sm-12 mt-30 text-center">
                                            <p>By creating an account, you agree to our <a href="#">terms</a> and <a
                                                href="#">privacy policy</a>.</p>
                                            <p>Already have an account? <a href="#">Sign in!</a></p>
                                        </div>*/}
                                        </div>
                                    </fieldset>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegistrarComponent
