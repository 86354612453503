import React from "react"
import { navigate } from "gatsby"
import {  isLoggedIn } from "../services/auth"
import Layout from "../components/layout";
import RegistrarComponent from "../app/registrarComponent";

class Registrar extends React.Component {

    render() {

        return (
            <Layout>
                <RegistrarComponent />
            </Layout>
        );
    }
}

export default Registrar
